// Direttiva che formatta la data in UTC
angular.module('app').directive('datetimepickerNeutralTimezone', function () {
    return {
        restrict: 'A',
        priority: 1,
        require: 'ngModel',
        link: function (scope: any, element: any, attrs: any, ctrl: any) {
            ctrl.$formatters.push(function (value: any) {
                var date = new Date(Date.parse(value));
                date = new Date(date.getTime() + (60000 * date.getTimezoneOffset()));
                return date;
            });

            ctrl.$parsers.push(function (value: any) {
                var date: Date = null;
                if (value) {
                    date = new Date(value.getTime() - (60000 * value.getTimezoneOffset()));
                }
                return date;
            });
        }
    };
});